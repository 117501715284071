<template>
  <div>
    <!-- <div class="back-button">
      <p class="text-hoverable text--primary" @click="$router.go(-1)" style="display: flex; justify-content: flex-start; align-items: center; margin: 0">
        <BackIcon style="margin-right: 10px"/>Tillbaka till nyheter
      </p>
    </div> -->
    <news-article :title="data.title" :content="data.content" :date="date" :images="data.images" />
  </div>
</template>

<script>
import ContentSheet from '../components/ContentSheet.vue'
import NewsArticle from '../components/news/NewsArticle.vue'
import moment from 'moment'
import BackIcon from '../assets/icons/back_primary.svg'

export default {
  methods: {
    getData() {
      for(const data of Object.entries(this.$store.state.news)) {
        if(data[1].slug === this.$route.params.slug) return data[1]
      }
      return {
        title: ['Kunde inte hitta denna artikel','Aricle not found'],
        content: ['',''],
        date: '',
        images: [],
      }
    }
  },
  metaInfo() {
    return {
      title: this.data.title[+(this.$i18n.locale != 'sv')]
    }
  },
  computed: {
    date() {
      moment.locale(this.$i18n.locale)
      return moment(this.data.time).utc().format('MMM DD, YYYY')
    },
    data() {
      const data = this.getData()
      return data
    },
  },
  components: {
    NewsArticle,
    BackIcon,
    ContentSheet,
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style>
.news {
  display: flex;

}

.back-button {
  display: flex;
  max-width: 1000px; 
  margin: 30px auto;
}

@media screen and (max-width: 1150px) {
  .back-button {
    display: flex;
    max-width: 1000px; 
    padding: 0 5%;
  }
}
</style>